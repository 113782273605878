html, body, #app {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

#app {
  padding: 40px;
}

#app, #app select, #app option, #app button {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.app-container[data-v-5169be] {
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;
}

.app-container--body[data-v-5169be] {
  flex-grow: 1;
}

.app-container--footer[data-v-5169be] {
  text-align: center;
  font-size: 12px;
}

.action-form form[data-v-fccbad] {
  margin-bottom: 10px;
}

.action-form form select[data-v-fccbad] {
  border: none;
  font-weight: 700;
}

.actions-list--form[data-v-76cd8b] {
  margin-bottom: 40px;
}

.actions-list--item[data-v-76cd8b] {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.page-home[data-v-f0831d] {
  max-width: 600px;
}

.page-home .profile-pic[data-v-f0831d] {
  border: 2px solid #000;
  border-radius: 50%;
  height: 100%;
  margin: 10px;
}

.page-home .greeting[data-v-f0831d] {
  margin-bottom: 40px;
}

.page-home .greeting--header[data-v-f0831d] {
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}

.page-login[data-v-ccee50] {
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  height: 100%;
  display: flex;
}

.page-login .greeting[data-v-ccee50], .page-login .description[data-v-ccee50] {
  margin: 0;
}

.page-login .login-btn[data-v-ccee50] {
  background-color: #0000;
  border: 2px solid #000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  transition: background-color .25s ease-out;
  display: flex;
}

.page-login .login-btn[data-v-ccee50]:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}

.page-login .login-btn[disabled][data-v-ccee50] {
  border-color: #999;
}

.page-login .login-btn[disabled] .service-icon[data-v-ccee50] {
  display: none;
}

.page-login .login-btn .service-icon[data-v-ccee50] {
  height: 20px;
}

.page-signup[data-v-5f1bf7] {
  max-width: 600px;
}

.page-signup .profile-pic[data-v-5f1bf7] {
  border: 2px solid #000;
  border-radius: 50%;
  margin: 10px;
}

.page-signup .greeting[data-v-5f1bf7] {
  margin-bottom: 40px;
}

.page-signup .greeting--header[data-v-5f1bf7] {
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}

.emotes[data-v-e59aee] {
  flex-wrap: wrap;
  gap: 20px;
  max-width: 600px;
  display: flex;
}

.emotes .emote[data-v-e59aee] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.emotes .emote img[data-v-e59aee] {
  filter: drop-shadow(0 0 .2rem #000);
  border: 2px solid #000;
  border-radius: 4px;
  width: 112px;
  height: 112px;
}

.emotes .emote .username[data-v-e59aee] {
  font-size: 12px;
  font-weight: bold;
}

.app-container[data-v-5169be] {
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;
}

.app-container--body[data-v-5169be] {
  flex-grow: 1;
}

.app-container--footer[data-v-5169be] {
  text-align: center;
  font-size: 12px;
}

.action-form form[data-v-fccbad] {
  margin-bottom: 10px;
}

.action-form form select[data-v-fccbad] {
  border: none;
  font-weight: 700;
}

.actions-list--form[data-v-76cd8b] {
  margin-bottom: 40px;
}

.actions-list--item[data-v-76cd8b] {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.page-home[data-v-f0831d] {
  max-width: 600px;
}

.page-home .profile-pic[data-v-f0831d] {
  border: 2px solid #000;
  border-radius: 50%;
  height: 100%;
  margin: 10px;
}

.page-home .greeting[data-v-f0831d] {
  margin-bottom: 40px;
}

.page-home .greeting--header[data-v-f0831d] {
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}

.page-login[data-v-ccee50] {
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  height: 100%;
  display: flex;
}

.page-login .greeting[data-v-ccee50], .page-login .description[data-v-ccee50] {
  margin: 0;
}

.page-login .login-btn[data-v-ccee50] {
  background-color: #0000;
  border: 2px solid #000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  transition: background-color .25s ease-out;
  display: flex;
}

.page-login .login-btn[data-v-ccee50]:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}

.page-login .login-btn[disabled][data-v-ccee50] {
  border-color: #999;
}

.page-login .login-btn[disabled] .service-icon[data-v-ccee50] {
  display: none;
}

.page-login .login-btn .service-icon[data-v-ccee50] {
  height: 20px;
}

.page-signup[data-v-5f1bf7] {
  max-width: 600px;
}

.page-signup .profile-pic[data-v-5f1bf7] {
  border: 2px solid #000;
  border-radius: 50%;
  margin: 10px;
}

.page-signup .greeting[data-v-5f1bf7] {
  margin-bottom: 40px;
}

.page-signup .greeting--header[data-v-5f1bf7] {
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}

.emotes[data-v-e59aee] {
  flex-wrap: wrap;
  gap: 20px;
  max-width: 600px;
  display: flex;
}

.emotes .emote[data-v-e59aee] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.emotes .emote img[data-v-e59aee] {
  filter: drop-shadow(0 0 .2rem #000);
  border: 2px solid #000;
  border-radius: 4px;
  width: 112px;
  height: 112px;
}

.emotes .emote .username[data-v-e59aee] {
  font-size: 12px;
  font-weight: bold;
}

/*# sourceMappingURL=index.e07a625a.css.map */
