
html,
body,
#app {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

#app {
  padding: 40px;

  // TODO: Would an html reset address this?
  &,
  select,
  option,
  button {
    font-family: 'Poppins', sans-serif;
    // TODO: Look into a more dynamic font-size.
    font-size: 16px;
  }
}
